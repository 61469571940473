import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import avatar from "../images/avatar.png";
import deBruijn from "../images/de-bruijn.jpeg";
import visaImage from "../images/visa.jpg";
// import hospitalReport2 from "../images/hospital-report2.webp";
import mapImage from "../images/map.jpg";
import luklaImage from "../images/lukla.png";
import monjoImage from "../images/MONJO.png";
import namcheImage from "../images/NAMCHE.png";

const Patient = () => {
  const [modalImage, setModalImage] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [showBackgroundImage, setShowBackgroundImage] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [showError, setShowError] = useState(false);
  const [placeId, setPlaceId] = useState("");
  const { id } = useParams();
  // 0498531990;
  // const patientIdList =[1234567, 5896321, 7654321, 8962412,4568795]

  useEffect(() => {
    function getPlaceId() {
      switch (id) {
        case "lukla":
          setPlaceId("Lukla");
          break;
        case "manjo":
          setPlaceId("Manjo");
          break;
        case "namche":
          setPlaceId("Namche");
          break;
        default:
          setPlaceId("");
          break;
      }
    }
    getPlaceId();
  }, [id]);
  console.log(placeId);
  // 4390938;
  // 10919946;
  // 5011210;
  // 8383498;
  // 1780746;
  useEffect(() => {
    if (
      patientId === "0004390938" ||
      patientId === "0010919946" ||
      patientId === "0005011210" ||
      patientId === "0008383498" ||
      patientId === "0001780746"
    ) {
      console.log("patiend id", patientId);
      setShowError(false);
      setShowBackgroundImage(false);
      async function saveTapCount() {
        try {
          const response = await axios.post(
            "https://api.uniboard.unitechmedia.com.np/api/saveTapCount",
            {
              card_number: patientId,
              master_link: "trekcard2",
              place: placeId,
            }
          );
          if (response) {
            console.log(response);
            setShowInfo(true);
          }
        } catch (error) {
          console.log("errir", error);
        }
      }
      saveTapCount();
    } else if (patientId === "") {
      setShowError(false);
      setShowInfo(false);
      setShowBackgroundImage(true);
    } else if (
      patientId !== "0004390938" ||
      patientId !== "0010919946" ||
      patientId !== "0005011210" ||
      patientId !== "0008383498" ||
      patientId !== "0001780746"
    ) {
      setShowInfo(false);
      patientId.length >= 10 && setShowError(true);
      setShowBackgroundImage(true);
    }
  }, [patientId, placeId]);
  function handleSubmit(e) {
    e.preventDefault();
  }
  return (
    <div className="patient-container">
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="">Tourist Id</label>
          <input
            type="text"
            onInput={(e) => setPatientId(e.target.value)}
            value={patientId}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowInfo(false);
              setPatientId("");
            }}
          >
            Clear
          </button>
        </div>
        {showError && (
          <p className="text-danger">The Tourist ID is not valid.</p>
        )}
      </form>
      {showBackgroundImage && (
        <div
          className="background-image"
          style={{ width: "100vw", position: "absolute", left: "0" }}
        >
          {id === "lukla" && <img src={luklaImage} alt="Lukla" />}
          {id === "manjo" && <img src={monjoImage} alt="Lukla" />}
          {id === "namche" && <img src={namcheImage} alt="Lukla" />}
        </div>
      )}
      {showInfo && (
        <div className="patient-info">
          <div className="row">
            <div className="col-md-4" style={{ alignSelf: "center" }}>
              <div className="card" style={{ border: "none" }}>
                <div className="profile-image">
                  <div className="image-container">
                    <img src={avatar} alt="" />
                  </div>
                </div>
                <div className="text-container">
                  <div className="text-field">
                    <p className="label">Name:</p>
                    <p>Test Name</p>
                  </div>
                  <div className="text-field">
                    <p className="label">Phone:</p>
                    <p>0123456789</p>
                  </div>
                  <div className="text-field">
                    <p className="label">Email:</p>
                    <p>testmail@gmail.com</p>
                  </div>
                  <div className="text-field">
                    <p className="label">Age:</p>
                    <p>24</p>
                  </div>
                  {/* <div className="text-field">
                    <p className="label">Doctor's Name:</p>
                    <p>Test Doctor</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <>
                {modalImage && (
                  <div
                    className="image-modal-outer"
                    onClick={(e) => {
                      setModalImage(false);
                    }}
                  >
                    <div
                      className="image-modal"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="image-container">
                        <img src={modalImage} alt="" />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="report-header">
                    <h4>Documents</h4>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="report-card"
                      onClick={(e) => setModalImage(deBruijn)}
                    >
                      <div className="overlay"></div>
                      <div className="image-container">
                        <img src={deBruijn} alt="" />
                      </div>
                      <div className="report-caption">
                        <h6>Passport</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="report-card"
                      onClick={(e) => setModalImage(visaImage)}
                    >
                      <div className="overlay"></div>
                      <div className="image-container">
                        <img src={visaImage} alt="" />
                      </div>
                      <div className="report-caption">
                        <h6>Visa</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="report-card"
                      onClick={(e) => setModalImage(mapImage)}
                    >
                      <div className="overlay"></div>
                      <div className="image-container">
                        <img src={mapImage} alt="" />
                      </div>
                      <div className="report-caption">
                        <h6>Trekking Company</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="report-card"
                      onClick={(e) => setModalImage(visaImage)}
                    >
                      <div className="overlay"></div>
                      <div className="image-container">
                        <img src={visaImage} alt="" />
                      </div>
                      <div className="report-caption">
                        <h6>License</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Patient;
