import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Patient from "./Pages/Patient";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import PatientLog from "./Pages/PatientLog";

function App() {
  return (
    <>
      <div className="dashboard-inner-container" style={{ padding: "25px 0" }}>
        <div className="container">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/tourist/lukla" replace />}
              />
              <Route path="/tourist/log" element={<PatientLog />} />
              <Route path="/tourist/:id" element={<Patient />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}
export default App;
