import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { UNSAFE_LocationContext } from "react-router-dom";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PatientLog = () => {
  const [logData, setLogData] = useState(null);
  //   const location = useLocation();
  const [luklaData, setLuklaData] = useState(null);
  const [monjoData, setMonjoData] = useState(null);
  const [namcheData, setNamcheData] = useState(null);
  const [renderedData, setRenderedData] = useState([]);
  const [secondRenderedData, setSecondRenderedData] = useState(null);

  // array created to store all card numbers extract from fetched data
  let cardNumber = [];

  useState(() => {
    setInterval(getAllTapCount, 10000); //fetch data every 10 seconds

    async function getAllTapCount() {
      try {
        const response = await axios.get(
          "https://api.uniboard.unitechmedia.com.np/api/getAllTapCount"
        );
        if (response) {
          console.log(response.data);
          setLogData(response.data.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    getAllTapCount();
  }, []);

  // console.log(fetchCount);
  useEffect(() => {
    console.log("logData", logData);

    setLuklaData(logData && logData.filter((item) => item.place === "Lukla"));
    setMonjoData(logData && logData.filter((item) => item.place === "Manjo"));
    setNamcheData(logData && logData.filter((item) => item.place === "Namche"));
    if (logData) {
      logData.forEach((data) => {
        if (!cardNumber.includes(data.card_number)) {
          console.log("ddd");
          cardNumber.push(data.card_number);

          // prevent duplicate object from being pushed into renderedData array
          setRenderedData((oldData) =>
            cardNumber.length > oldData?.length
              ? [...oldData, { card_number: data.card_number }]
              : oldData
          );
        }
      });
    }
  }, [logData]);
  // create array to render for each card number containing all places infomration
  useEffect(() => {
    if (logData) {
      renderedData &&
        renderedData.forEach((number) => {
          console.log(cardNumber);
          // console.log("rendered data item", number);
          logData.forEach((data) => {
            if (data.card_number === number.card_number) {
              Object.assign(number, { name: data.name });
              if (data.place === "Lukla") {
                Object.assign(number, {
                  luklaStatus: data.count
                    ? getLocalTime(data.updated_at)
                    : "Not Tapped",
                });
              }

              if (data.place === "Manjo") {
                Object.assign(number, {
                  manjoSatus: data.count
                    ? getLocalTime(data.updated_at)
                    : "Not Tapped",
                });
              }
              if (data.place === "Namche") {
                Object.assign(number, {
                  namcheStatus: data.count
                    ? getLocalTime(data.updated_at)
                    : "Not Tapped",
                });
              }
            }
          });
        });
    }
    console.log("rendered datas", renderedData);
    setSecondRenderedData(renderedData);
  }, [logData, renderedData]);

  function getLocalTime(dateString) {
    // let date = new Date(Date.parse(`${dateString} UTC`)).toLocaleString(
    //   "en-US",
    //   {
    //     dateStyle: "medium",
    //     timeStyle: "short",
    //   }
    // );
    let dateInISO = new Date().toISOString(dateString);
    console.log("date ", dateString);
    let date =
      new Date(Date.parse(`${dateInISO}`)).toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      }) ||
      new Date(Date.parse(`${dateInISO} UTC`)).toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      });
    // return new Date(Date.parse(`${dateInISO}`)).toLocaleString("en-US", {
    //   dateStyle: "medium",
    //   timeStyle: "short",
    // });
    return date;
  }

  function getFullCardNumber(number) {
    const numberArray = String(number).split("");
    while (numberArray.length < 10) {
      numberArray.unshift("0");
    }
    return numberArray.join("");
  }
  return (
    <div className="patient-container">
      <div className="row">
        <div className="col-md-12" style={{ overflow: "auto" }}>
          {/* <h2 style={{ color: "#37aeaa", fontSize: "20px" }}>Manjo</h2> */}
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Card Number</th>
                <th scope="col">Lukla</th>
                <th scope="col">Manjo</th>
                <th scope="col">Namche</th>
              </tr>
            </thead>
            <tbody>
              {renderedData &&
                renderedData.map((item, index) => {
                  return (
                    <tr key={item.card_number}>
                      <td>{item.name}</td>
                      <td>{getFullCardNumber(item.card_number)}</td>
                      <td>{item.luklaStatus}</td>
                      <td>{item.manjoSatus}</td>
                      <td>{item.namcheStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* <div className="col-md-12" style={{ overflow: "auto" }}>
          <h2 style={{ color: "#37aeaa", fontSize: "20px" }}>Lukla</h2>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Card Number</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Tapped Date</th>
              </tr>
            </thead>
            <tbody>
              {luklaData &&
                luklaData.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.card_number}</td>
                      <td>{item.name}</td>
                      <td>{item.count ? "Tapped" : "Not Tapped"}</td>
                      <td>{item.count ? item.updated_at : ""}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="col-md-12" style={{ overflow: "auto" }}>
          <h2 style={{ color: "#37aeaa", fontSize: "20px" }}>Manjo</h2>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Card Number</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Tapped Date</th>
              </tr>
            </thead>
            <tbody>
              {monjoData &&
                monjoData.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.card_number}</td>
                      <td>{item.name}</td>
                      <td>{item.count ? "Tapped" : "Not Tapped"}</td>
                      <td>{item.count ? item.updated_at : ""}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="col-md-12" style={{ overflow: "auto" }}>
          <h2 style={{ color: "#37aeaa", fontSize: "20px" }}>Namche</h2>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Card Number</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Tapped Date</th>
              </tr>
            </thead>
            <tbody>
              {namcheData &&
                namcheData.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.card_number}</td>
                      <td>{item.name}</td>
                      <td>{item.count ? "Tapped" : "Not Tapped"}</td>
                      <td>{item.count ? item.updated_at : ""}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  );
};

export default PatientLog;
